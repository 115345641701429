import { useDlpStatus } from "../../utils/useDlpStatus";
import { type PolicyData } from "./types";
import { usePoliciesData } from "./usePoliciesData";

/**
 * These permission types cannot be used when on a custom permission until
 * their specific permission gets used
 */
export const limitedPermissionTypes: Record<string, string> = {
  data: "policiesData",
  iam: "policiesIAM",
};

export const policyNamesThatShouldSupportNewLabel: (string | undefined)[] = [
  "AWS IAM support access policy is not associated to any role",
  "AWS IAM password policy does not have a minimum of 14 characters",
  "AWS Elastic Load Balancer (ELB) with IAM certificate expiring in 90 days",
  "AWS IAM policy attached to users",
  "AWS IAM password policy does not have an uppercase character",
  "AWS IAM SSH keys for AWS CodeCommit have aged more than 90 days without being rotated",
  "AWS IAM deprecated managed policies in use by User",
  "AWS MFA not enabled for IAM users",
  "AWS IAM has expired SSL/TLS certificates",
  "AWS IAM policy allows assume role permission across all services",
  "AWS IAM user has both Console access and Access Keys",
  "AWS Log metric filter and alarm does not exist for IAM policy changes",
  "AWS EC2 Instance IAM Role not enabled",
  "AWS IAM password policy does not have a number",
  "AWS IAM password policy allows password reuse",
  "AWS IAM password policy does not have password expiration period",
  "AWS ECS/Fargate task definition execution IAM Role not found",
  "AWS IAM Password policy is unsecure",
  "AWS Elasticsearch IAM policy overly permissive to all traffic",
  "AWS IAM policy allows full administrative privileges",
  "Account hijacking attempts",
  "Unusual user activity",
  "Excessive login failures",
  "AWS IAM user has two active Access Keys",
  "AWS IAM password policy does not have a lowercase character",
  "AWS IAM password policy does not expire in 90 days",
  "AWS IAM password policy does not have a symbol",
  "AWS IAM configuration updates invoked from Kali Linux machine",
  "AWS IAM policy overly permissive to STS services",
  "AWS IAM configuration updates invoked from Parrot Security Linux machine",
  "AWS IAM configuration updates invoked from Pentoo Linux machine",
  "AWS IAM Groups with administrator access permissions",
  "AWS ECS IAM policy overly permissive to all traffic",
  "AWS IAM policy is overly permissive to all traffic via condition clause",
  "AWS RDS instance not configured with IAM authentication",
  "AWS IAM policy allows decryption actions on all KMS keys",
  "AWS IAM role/user with unused CloudTrail delete or full permission",
  "AWS IAM Roles with Administrator Access Permissions",
  "AWS SageMaker notebook instance IAM policy overly permissive to all traffic",
  "AWS RDS cluster not configured with IAM authentication",
  "IAM policies allow privilege escalation",
  "IAM authentication for Amazon RDS clusters is disabled",
  "IAM groups do not include at least one IAM user",
  "Not all IAM users are members of at least one IAM group",
  "IAM User has access to the console",
  "IAM policies that allow full administrative privileges are created",
  "IAM role does not allow only specific services or principals to be assumed",
  "IAM users are assigned Service Account User or Service Account Token creator roles at project level",
  "IAM policy documents allow * (asterisk) as a statement's action",
];
export function useHasConfigIamPolicies(): boolean {
  const { allPolicies } = usePoliciesData();

  if (!allPolicies) {
    return false;
  }

  return allPolicies.some((policy: PolicyData) =>
    policyNamesThatShouldSupportNewLabel.includes(policy.name),
  );
}

export function useIsDataSecurityProvisioned(): boolean {
  const { data } = useDlpStatus();

  return data?.status === "provisionSuccessful";
}
