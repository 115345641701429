import { z } from "zod";
import { PolicyTypesEnumSchema, SeverityEnumSchema } from "../policies";
import { AssetGraphResponseSchema } from "../rql";
import {
  TopVulnerabilitiesSchema,
  VulnerabilitiesSearchItemSchema,
  assetCategoriesTypes,
} from "../unifiedVulnerabilities";

export const copilotRequestTypes = [
  "DOC_SEARCH",
  "GENERATE_RQL",
  "RQL_TO_TEXT",
  "EXPLAIN_JSON",
  "ASSET_SEARCH",
  "RECOMMENDED_QUESTIONS",
  "PCS_API_CALL",
  "SAVED_SEARCH",
  "PCS_ACCOUNT_ONBOARDING",
  "ONBOARDING_DOWNLOAD_CFT_TEMPLATE",
  "SUPPORT_DOC_SEARCH",
  "NAVIGATION",
  "TOP_RISKS",
  "VULNERABILITY_SEARCH",
  "PRIORITIZATION",
  "EXPLAIN_JSON_GRAPH",
  "EXPLAIN_JSON_THREAT",
  "OPEN_CASE",
  "FOLLOW_UP_PROMPTS_CLARIFICATION",
  "DEFAULT",
] as const;

const copilotRequestTypeEnum = z.enum(copilotRequestTypes);

const followUpPromptFunctionalityType = [
  "MESSAGE",
  "DOWNLOAD",
  "REDIRECT",
] as const;

export const followUpPromptAppearanceType = [
  "primary",
  "secondary",
  "danger",
  "clear",
  "tertiary",
  "tertiary-clear",
] as const;

const followUpPromptFunctionalityTypeEnum = z.enum(
  followUpPromptFunctionalityType,
);

const followUpPromptAppearanceTypeEnum = z.enum(followUpPromptAppearanceType);

export const CopilotRequestTypesSchema = z.array(
  z.object({
    value: z.string(),
    id: copilotRequestTypeEnum,
  }),
);

export const FollowUpPromptBaseSchema = z.object({
  intentHint: z.string(),
  displayText: z.string().optional(),
  requestType: copilotRequestTypeEnum.optional(),
  functionalityType: followUpPromptFunctionalityTypeEnum
    .default("MESSAGE")
    .optional(),
  disabled: z.boolean().optional(),
  appearance: followUpPromptAppearanceTypeEnum.default("secondary").optional(),
});

export const DownloadFollowUpPromptSchema = FollowUpPromptBaseSchema.extend({
  functionalityType: z.literal("DOWNLOAD"),
  downloadData: z.array(
    z.object({
      cveId: z.string().optional(),
      assetType: assetCategoriesTypes.optional(),
    }),
  ),
});

export type DownloadFollowUpPrompt = z.infer<
  typeof DownloadFollowUpPromptSchema
>;

export const RedirectFollowUpPromptSchema = FollowUpPromptBaseSchema.extend({
  functionalityType: z.literal("REDIRECT"),
  redirectUrl: z.string(),
});

export type RedirectFollowUpPrompt = z.infer<
  typeof RedirectFollowUpPromptSchema
>;

export const MessageFollowUpPromptSchema = FollowUpPromptBaseSchema.extend({
  functionalityType: z.literal("MESSAGE"),
});

export type MessageFollowUpPrompt = z.infer<typeof MessageFollowUpPromptSchema>;

export const FollowupPromptSchema = z.discriminatedUnion("functionalityType", [
  MessageFollowUpPromptSchema,
  RedirectFollowUpPromptSchema,
  DownloadFollowUpPromptSchema,
]);
export type FollowupPrompt = z.infer<typeof FollowupPromptSchema>;

const copilotPromptResponseBaseSchema = z.object({
  userName: z.string().optional(),
  author: z.string().optional(),
  confidenceScore: z.string().nullish(),
  conversationId: z.string().uuid(),
  messageId: z.string().uuid(),
  requestType: copilotRequestTypeEnum.nullable(),
  text: z.string().nullish(),
  currentContext: z.string(),
  followUpPrompts: z.array(FollowupPromptSchema).nullish(),
  followUpPromptsTitle: z.string().optional(),
  gatewayRequestTs: z.number().optional(),
  responseTs: z.number().optional(),
});

export type CopilotRequestType = z.infer<typeof copilotRequestTypeEnum>;

export const CopilotFeedbackRequestSchema = z.object({
  /** The ID of the message this feedback is for */
  messageId: copilotPromptResponseBaseSchema.shape.messageId,
  /** Rating of the response, from 1-4 stars*/
  responseValidity: z.number().min(1).max(4),
  feedbackProvider: z.string().optional(),
  /** Any custom comments to pass with the feedback */
  comments: z.string().optional(),
  suggestions: z.array(z.string()).optional(),
});
export type CopilotFeedbackRequest = z.infer<
  typeof CopilotFeedbackRequestSchema
>;

export const CopilotFeedbackResponseSchema = z.object({
  id: z.string().uuid(),
});

export const CopilotPromptRequestSchema = z.object({
  requestText: z.string(),
  requestType: copilotRequestTypeEnum.optional(),
  conversationId:
    copilotPromptResponseBaseSchema.shape.conversationId.optional(),
  context: z
    .object({
      currentContext: z.string().optional(),
      requestType: copilotRequestTypeEnum.optional(),
      intentHint: z.string().optional(),
      parentMessageId: z.string().optional(),
    })
    .optional(),
});
export type CopilotPromptRequest = z.infer<typeof CopilotPromptRequestSchema>;

export const CopilotOnboardingTemplateRequestSchema = z.object({
  cloudAccount: z.string(),
  isOrg: z.boolean(),
  cloudName: z.string().optional(),
});
export type CopilotOnboardingTemplateRequest = z.infer<
  typeof CopilotOnboardingTemplateRequestSchema
>;

const TextFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("TEXT"),
  responseJson: z.object({
    content: z
      .array(
        z.object({
          /** Text for the context */
          context: z.string(),
          /** The score as a decimal string. eg: 70% is "0.70" */
          relevanceScore: z.string(),
          hyperlink: z.string().url(),
        }),
      )
      .optional(),
  }),
});

const DocSearchSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("MATCHED_CONTEXT"),
  responseJson: z.object({
    content: z
      .array(
        z.object({
          /** Text for the context */
          context: z.string(),
          /** The score as a decimal string. eg: 70% is "0.70" */
          relevanceScore: z.string(),
          hyperlink: z.string().url(),
        }),
      )
      .optional(),
  }),
});

export type DocSearchMessageResponse = z.infer<typeof DocSearchSchema>;

const ApiAgentSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("API_AGENT"),
  responseJson: z.object({
    drop_down: z.string().optional(),
  }),
});

export type ApiAgentMessageResponse = z.infer<typeof ApiAgentSchema>;

const SupportCaseRemediationSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("CASE_REMEDIATION"),
  responseJson: z.object({
    prefix: z.string(),
    remediation: z.string(),
    severity: SeverityEnumSchema.optional(),
    lastOccurrence: z.date().optional(),
  }),
});

export const CreateSupportCaseResponseSchema =
  copilotPromptResponseBaseSchema.extend({
    format: z.literal("CASE_CREATION_SUCCESS"),
    responseJson: z.object({
      CaseURL: z.string(),
      CaseNumber: z.string(),
      CaseId: z.string(),
      UploadDetails: z
        .array(
          z.object({
            fileName: z.string(),
            type: z.string(),
            fileUploadUrl: z.string(),
          }),
        )
        .nullish(),
    }),
  });

const SupportTicketFormSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("SUPPORT_TICKET_FORM"),
  responseJson: z
    .object({
      taxonomy: z.object({
        product_areas: z.array(z.object({}).passthrough()),
      }),
    })
    .passthrough(),
});

const OnboardingTemplateFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("TEMPLATE"),
  responseJson: z.object({
    cloudAccount: z.string(),
    isOrg: z.boolean(),
    cloudName: z.string().optional(),
  }),
});

export const ExplanationsSchema = z.object({
  summary: z.string().optional(),
  criticalRisk: z.string().optional(),
  exploitationSteps: z.string().optional(),
});
export const GraphFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("GRAPH"),
  responseJson: z.object({
    content: z.discriminatedUnion("type", [
      z.object({
        type: z.literal("organic"),
        graphs: AssetGraphResponseSchema.shape.graphs,
      }),
      z.object({
        type: z.literal("swimlane"),
        graphs: AssetGraphResponseSchema.shape.graphs,
      }),
      z.object({
        type: z.literal("vulnerabilities"),
        items: z.array(VulnerabilitiesSearchItemSchema),
        totalRows: z.number(),
      }),
    ]),
    explanations: ExplanationsSchema.optional(),
  }),
  responseRql: z.string().optional(),
});

export type GraphFormat = z.infer<typeof GraphFormatSchema>;

const DynamicTableFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("TABLE"),
  responseJson: z.object({
    columns: z.array(
      z.object({
        name: z.string(),
        accessor: z.string(),
        label: z.string().optional(),
        /**
         * NOTE: Do NOT add more types. If you need another type for a more custom table, create a new format
         * with a specific data format like TOP_RISKS_BY_POLICY and then you can control the columns in the renderer.
         *
         * We don't want to make the same mistakes of the past.
         * https://git.scm.prismacloud.io/prismacloud/ui/prisma-ui/-/blob/develop/src/sharedUtils/widgetUtils/widgetUtils.js#L1913
         */
        type: z.union([z.literal("text"), z.literal("prompt")]).optional(),
      }),
    ),
    rows: z.array(z.object({}).passthrough()),
  }),
});

const PolicyTopRisksFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("TOP_RISK_BY_POLICY"),
  responseJson: z.object({
    data: z.array(
      z.object({
        "policy.name": z.string(),
        "policy.severity": SeverityEnumSchema,
        "policy.policyType": PolicyTypesEnumSchema,
        count: z.number(),
        action: FollowupPromptSchema.optional(),
      }),
    ),
  }),
});
export type PolicyTopRisksFormat = z.infer<typeof PolicyTopRisksFormatSchema>;

const TopVulnerabilitiesFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("TOP_VULNERABILITIES"),
  responseJson: z.object({
    data: z.array(
      TopVulnerabilitiesSchema.extend({
        action: FollowupPromptSchema.optional(),
      }),
    ),
  }),
});
export type TopVulnerabilitiesFormat = z.infer<
  typeof TopVulnerabilitiesFormatSchema
>;

const AlertByResourceFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("ALERT_BY_RESOURCE"),
  responseJson: z.object({
    data: z.array(
      z.object({
        "resource.name": z.string(),
        "resource.cloudType": z.string(),
        "resource.resourceType": z.string(),
        "resource.accountId": z.string(),
        "resource.account": z.string(),
        "resource.regionId": z.string(),
        accountOwner: z.string(),
        alertTime: z.number(),
        action: FollowupPromptSchema.optional(),
      }),
    ),
  }),
});
export type AlertByResourceFormat = z.infer<typeof AlertByResourceFormatSchema>;

const AlertAccordionFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("ALERT_ACCORDION"),
  responseJson: z.object({
    data: z.array(
      z.object({
        owner: z.string().nullish(),
        name: z.string(),
        description: z.string(),
        applicationId: z.string().nullish(),
        type: z.enum(["graph", "table", "swimlane", "text"]),
        followUpPrompts: z.array(FollowupPromptSchema).optional(),
        extraActions: z.array(FollowupPromptSchema).optional(),
        graphs: AssetGraphResponseSchema.shape.graphs.nullish(),
        severity: z.enum([
          "critical",
          "high",
          "medium",
          "low",
          "informational",
        ]),
        textData: z.string().nullish(),
        rank: z.number(),
      }),
    ),
  }),
});
export type AlertAccordionFormat = z.infer<typeof AlertAccordionFormatSchema>;

const ImpactedAssetsFormatSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("IMPACTED_ASSETS"),
  responseJson: z.object({
    query: z.object({
      cve_id: z.string(),
      filter_suppressed: z.boolean(),
    }),
  }),
});

const VulnerabilityDownloadCveSchema = copilotPromptResponseBaseSchema.extend({
  format: z.literal("VULNERABILITY_DOWNLOAD_CVE"),
  responseJson: z.object({
    downloads: z.object({
      method: z.string(),
      path: z.string(),
      payloads: z.array(
        z.object({
          cveId: z.string(),
          assetType: assetCategoriesTypes,
        }),
      ),
    }),
  }),
});

export const FollowUpPromptClarificationSchema =
  copilotPromptResponseBaseSchema.extend({
    format: z.literal("CLARIFICATION"),
    responseJson: z.object({
      data: z.array(FollowupPromptSchema),
      footerText: z.string(),
      actionText: z.string(),
    }),
    parentMessageId: z.string(),
  });

export const CopilotPromptResponseSchema = z.discriminatedUnion("format", [
  TextFormatSchema,
  DocSearchSchema,
  ApiAgentSchema,
  OnboardingTemplateFormatSchema,
  GraphFormatSchema,
  DynamicTableFormatSchema,
  PolicyTopRisksFormatSchema,
  AlertByResourceFormatSchema,
  TopVulnerabilitiesFormatSchema,
  AlertAccordionFormatSchema,
  SupportCaseRemediationSchema,
  SupportTicketFormSchema,
  CreateSupportCaseResponseSchema,
  ImpactedAssetsFormatSchema,
  VulnerabilityDownloadCveSchema,
  FollowUpPromptClarificationSchema,
]);

export type CopilotPromptResponse = z.infer<typeof CopilotPromptResponseSchema>;

export const CopilotHistorySchema = z.array(
  z.object({
    userName: z.string(),
    conversationName: z.string(),
    conversationId: z.string().uuid(),
    messageId: z.string().uuid(),
    messageText: z.string(),
    gatewayRequestTs: z.number(),
    responseTs: z.number(),
  }),
);

export type CopilotHistory = z.infer<typeof CopilotHistorySchema>;

export const CopilotFavoritesSchema = z.array(
  z.object({
    messageText: z.string(),
    messageId: z.string().uuid(),
    updateTs: z.number(),
  }),
);

export const CopilotFavoritesRequestSchema = z.object({
  messageId: z.string().uuid(),
  favorite: z.boolean(),
});

export type CopilotFavoritesRequest = z.infer<
  typeof CopilotFavoritesRequestSchema
>;

export const ConversationEditNameRequestSchema = z.object({
  name: z.string(),
});

export type ConversationEditNameRequest = z.infer<
  typeof ConversationEditNameRequestSchema
>;

export const CreateSupportCaseRequestSchema = z.object({
  conversationId: z.string().uuid(),
  phoneNumber: z.string(),
  issueDescription: z.string(),
  productArea: z.string(),
  category: z.string(),
  fileNames: z.array(z.string()).optional(),
  lastOccurrence: z.string().optional(),
  severity: z.enum(["P0", "P1", "P2", "P3", "P4"]),
  caseQuestionnaire: z
    .array(
      z.object({
        question: z.string(),
        answer: z.string(),
      }),
    )
    .optional(),
});

const ResponseFromCacheSchema = z.object({
  prompt: z.string(),
  isAIGenerated: z.boolean(),
});

const ResponseFromHistorySchema = z.object({
  prompt: z.string(),
  timestamp: z.number(),
});

export const CopilotSuggestionsResponseSchema = z.object({
  responsesFromHistory: z.array(ResponseFromHistorySchema).nullish(),
  responsesFromCache: z.array(ResponseFromCacheSchema).nullish(),
  responsesFromFavorites: z.array(z.string()).nullish(),
});

export type CopilotSuggestionsResponse = z.infer<
  typeof CopilotSuggestionsResponseSchema
>;

export type CopilotSuggestionsRequest = {
  request: string;
  ui_url?: string;
};

export type CopilotCheckTosResponse = z.infer<
  typeof CopilotCheckTosResponseSchema
>;

const TosFeatureSchema = z.enum(["ga", "beta"]);

export const CopilotCheckTosResponseSchema = z.array(
  z.object({
    feature: TosFeatureSchema,
    tos_accepted: z.boolean(),
    enabled: z.boolean(),
  }),
);

z.object({
  message: z.string(),
});

export type CopilotTosRequest = z.infer<typeof CopilotTosRequestSchema>;

export const CopilotTosRequestSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  feature: TosFeatureSchema,
});

export const CopilotBetaFeaturesEnableSchema = z.object({
  enabled: z.boolean(),
});

export type CopilotBetaFeaturesEnable = z.infer<
  typeof CopilotBetaFeaturesEnableSchema
>;
