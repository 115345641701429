import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useComputeEnabled, usePermissions } from "prisma";
import {
  cloudAccountsKeys,
  getCloudAccountsTableData,
  getPolicies,
  policiesKeys,
  useToken,
  type Token,
} from "requests";
import { dashboardsSavedViews } from "saved-views-next";
import { useIamStatus } from "../../../../utils/useIamStatus";
import { useIsDataSecurityProvisioned } from "../../../governance/utils";
import { type swimlanes } from "../../components/DownloadReport/utils";
import { hasSystemAdminPermission } from "../shared/utils";

export type ModuleStatus =
  | "ok"
  | "no_network_config_policy"
  | "no_ok_cloud_account"
  | "feature_disabled"
  | "not_supported";

export function useCommandCenterModuleStatus(): {
  isLoading: boolean;
  status: {
    [K in (typeof swimlanes)[number]]: ModuleStatus;
  };
} {
  const { data: view, isLoading: isViewsLoading } =
    dashboardsSavedViews.useView("command-center");
  const isIncidentsSupported =
    view &&
    view.settings?.charts?.some((c) => c.component === "topIncidentsByPolicy");
  const isAttackPathSupported =
    view &&
    view.settings?.charts?.some(
      (c) => c.component === "topAttackPathsByPolicy",
    );
  const isMisconfigurationsSupported =
    view &&
    view.settings?.charts?.some(
      (c) => c.component === "topMisconfigurationsByPolicy",
    );
  const isExposuresSupported =
    view &&
    view.settings?.charts?.some((c) => c.component === "topExposuresByPolicy");
  const isIamSupported =
    view &&
    view.settings?.charts?.some(
      (c) => c.component === "topIdentityRisksByPolicy",
    );
  const isVulnerabilitiesSupported =
    view &&
    view.settings?.charts?.some((c) => c.component === "urgentVulnerabilities");
  const isDlpSupported =
    view &&
    view.settings?.charts?.some((c) => c.component === "topDataRisksByPolicy");

  const token = useToken();
  const isSystemAdmin = hasSystemAdminPermission(token as Token);
  const permissions = usePermissions();

  const { isLoading: isCloudAccountLoading, data: cloudAccounts } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsTable({
      excludeAccountGroupDetails: "true",
    }),
    queryFn: getCloudAccountsTableData,
    staleTime: Infinity,
    enabled: permissions?.settingsCloudAccounts?.READ,
  });
  const isCloudAccountOnboarded = cloudAccounts?.some(
    (c) => c.status !== "error",
  );

  const { isLoading: isIamLoading, isIamModuleEnabled: isIAMEnabled } =
    useIamStatus(true);
  const computeEnabled = useComputeEnabled();
  const isPCDSEnabled = useIsDataSecurityProvisioned();
  const policies = useQuery({
    queryKey: policiesKeys.policies({
      slimView: "true",
      detailedComplianceMappings: "false",
      "policy.type": "network",
      "policy.subtype": "network_config",
    }),
    queryFn: getPolicies,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    enabled: permissions?.policies?.READ,
  });

  const isLoading =
    isViewsLoading ||
    (permissions?.settingsCloudAccounts?.READ && isCloudAccountLoading) ||
    isIamLoading ||
    (permissions?.policies?.READ && policies.isLoading);

  const networkConfigPolicyEnabled =
    policies === undefined ||
    (policies.isSuccess && policies.data.some((res) => !!res?.enabled));

  const status: {
    [K in (typeof swimlanes)[number]]: ModuleStatus;
  } = {
    incidents:
      isSystemAdmin && isIncidentsSupported
        ? isCloudAccountOnboarded
          ? "ok"
          : "no_ok_cloud_account"
        : "not_supported",
    "attack-path":
      isSystemAdmin && isAttackPathSupported
        ? isCloudAccountOnboarded
          ? "ok"
          : "no_ok_cloud_account"
        : "not_supported",
    misconfigurations:
      isSystemAdmin && isMisconfigurationsSupported
        ? isCloudAccountOnboarded
          ? "ok"
          : "no_ok_cloud_account"
        : "not_supported",
    exposures:
      isSystemAdmin && isExposuresSupported
        ? isCloudAccountOnboarded
          ? networkConfigPolicyEnabled
            ? "ok"
            : "no_network_config_policy"
          : "no_ok_cloud_account"
        : "not_supported",
    iam:
      isSystemAdmin && isIamSupported
        ? isIAMEnabled
          ? isCloudAccountOnboarded
            ? "ok"
            : "no_ok_cloud_account"
          : "feature_disabled"
        : "not_supported",
    workload_vulnerability:
      isSystemAdmin && computeEnabled && isVulnerabilitiesSupported
        ? isCloudAccountOnboarded
          ? "ok"
          : "no_ok_cloud_account"
        : "not_supported",
    "data-security":
      isSystemAdmin && isDlpSupported
        ? isPCDSEnabled
          ? isCloudAccountOnboarded
            ? "ok"
            : "no_ok_cloud_account"
          : "feature_disabled"
        : "not_supported",
  };

  return {
    isLoading,
    status,
  };
}
