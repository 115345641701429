import { isChina, isGov } from "environment";
import { useFormContext } from "form";
import { InfoTooltipIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FormattedMessage, useIntl } from "react-intl";
import {
  type FeatureNames,
  type Features,
  type PermissionOperations,
} from "requests";
import { Body, Button, Card, Tooltip } from "ui";
import { useIsAppDnaSupported } from "../../../../../../../inventory/applications/useIsAppDnaSupported";
import { Row, SelectAllCheckbox, useOperationSelectAll } from "./components";
import {
  ActionPlanSection,
  AlarmCenterSection,
  AlertsSection,
  ApplicationInventorySection,
  AssetInventorySection,
  CodeSecuritySection,
  ComplianceSection,
  ComputeSection,
  DashboardSection,
  DataSecurityPostureManagementSection,
  DataSecuritySection,
  InvestigateSection,
  PolicySection,
  SettingsSection,
} from "./sections";

export const AssignPermissionsBody = () => {
  const intl = useIntl();
  const { setValue, getValues } = useFormContext();
  const features = getValues().features ?? {};
  const { actionPlans } = useFlags();
  const isAppDnaSupported = useIsAppDnaSupported();

  const featureNames = Object.keys(features) as unknown as FeatureNames[];

  const { selectionStatus, onClickSelectAll } = useOperationSelectAll(
    featureNames.map((featureName) => ({
      featureName,
      operations: features[featureName],
    })) as Features[],
  );

  return (
    <>
      <Card>
        <Row
          addClassName="bg-gray-100 dark:bg-black/30"
          label={
            <>
              <Body as="div" addClassName="text-lg">
                <FormattedMessage
                  defaultMessage="Permissions"
                  id="GcxXCK"
                  description="Permission group header"
                />
              </Body>

              <div>
                <Button
                  appearance="clear"
                  size="sm"
                  onClick={() => {
                    const { features = {} } = getValues();
                    Object.entries(features).map(
                      ([featureName, operations]) => {
                        Object.keys(operations as PermissionOperations).forEach(
                          (operation) => {
                            setValue(
                              `features.${featureName}.${operation}`,
                              true,
                            );
                          },
                        );
                      },
                    );
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Enable All Permissions"
                    id="OVJdpf"
                    description="Enable all permissions button label"
                  />
                </Button>
              </div>
            </>
          }
          read={
            <>
              <div className="flex items-center">
                <FormattedMessage
                  defaultMessage="View"
                  id="iUJV1X"
                  description="Permission group header"
                />
                <Tooltip
                  label={intl.formatMessage({
                    defaultMessage:
                      "By selecting View you are allowing users to view sections of Prisma Cloud as read-only.",
                    id: "ddnK1a",
                    description: "Tooltip explaining permission type",
                  })}
                >
                  <span>
                    <InfoTooltipIcon
                      className="ml-1"
                      aria-label={intl.formatMessage({
                        defaultMessage:
                          "By selecting View you are allowing users to view sections of Prisma Cloud as read-only.",
                        id: "ddnK1a",
                        description: "Tooltip explaining permission type",
                      })}
                    />
                  </span>
                </Tooltip>
              </div>
              <SelectAllCheckbox
                status={selectionStatus.read}
                onChange={() => onClickSelectAll("READ")}
              >
                <FormattedMessage
                  defaultMessage="Select view for all rows"
                  id="YeWf+F"
                  description="Permission group header"
                />
              </SelectAllCheckbox>
            </>
          }
          create={
            <>
              <div className="flex items-center">
                <FormattedMessage
                  defaultMessage="Create"
                  id="Fw5NIZ"
                  description="Permission group header"
                />
                <Tooltip
                  label={intl.formatMessage({
                    defaultMessage:
                      "By selecting Create you are allowing users to create resources in Prisma Cloud.",
                    id: "P9dA+e",
                    description: "Tooltip explaining permission type",
                  })}
                >
                  <span>
                    <InfoTooltipIcon
                      className="ml-1"
                      aria-label={intl.formatMessage({
                        defaultMessage:
                          "By selecting Create you are allowing users to create resources in Prisma Cloud.",
                        id: "P9dA+e",
                        description: "Tooltip explaining permission type",
                      })}
                    />
                  </span>
                </Tooltip>
              </div>
              <SelectAllCheckbox
                status={selectionStatus.create}
                onChange={() => onClickSelectAll("CREATE")}
              >
                <FormattedMessage
                  defaultMessage="Select create for all rows"
                  id="tiIpUe"
                  description="Permission group header"
                />
              </SelectAllCheckbox>
            </>
          }
          update={
            <>
              <div className="flex items-center">
                <FormattedMessage
                  defaultMessage="Update"
                  id="WTMpff"
                  description="Permission group header"
                />
                <Tooltip
                  label={intl.formatMessage({
                    defaultMessage:
                      "By selecting Update you are allowing users to update resources in Prisma Cloud.",
                    id: "MJ5ry4",
                    description: "Tooltip explaining permission type",
                  })}
                >
                  <span>
                    <InfoTooltipIcon
                      className="ml-1"
                      aria-label={intl.formatMessage({
                        defaultMessage:
                          "By selecting Update you are allowing users to update resources in Prisma Cloud.",
                        id: "MJ5ry4",
                        description: "Tooltip explaining permission type",
                      })}
                    />
                  </span>
                </Tooltip>
              </div>
              <SelectAllCheckbox
                status={selectionStatus.update}
                onChange={() => onClickSelectAll("UPDATE")}
              >
                <FormattedMessage
                  defaultMessage="Select update for all rows"
                  id="nHLfC/"
                  description="Permission group header"
                />
              </SelectAllCheckbox>
            </>
          }
          del={
            <>
              <div className="flex items-center">
                <FormattedMessage
                  defaultMessage="Delete"
                  id="2TdcMf"
                  description="Permission group header"
                />
                <Tooltip
                  label={intl.formatMessage({
                    defaultMessage:
                      "By selecting Delete you are allowing users to delete resources in Prisma Cloud.",
                    id: "Iw2YC3",
                    description: "Tooltip explaining permission type",
                  })}
                >
                  <span>
                    <InfoTooltipIcon
                      className="ml-1"
                      aria-label={intl.formatMessage({
                        defaultMessage:
                          "By selecting Delete you are allowing users to delete resources in Prisma Cloud.",
                        id: "Iw2YC3",
                        description: "Tooltip explaining permission type",
                      })}
                    />
                  </span>
                </Tooltip>
              </div>
              <SelectAllCheckbox
                status={selectionStatus.delete}
                onChange={() => onClickSelectAll("DELETE")}
              >
                <FormattedMessage
                  defaultMessage="Select delete for all rows"
                  id="CpHvlc"
                  description="Permission group header"
                />
              </SelectAllCheckbox>
            </>
          }
        />
      </Card>

      <div className="flex flex-col overflow-auto">
        <Body as="div" addClassName="relative flex flex-col space-y-4 py-4">
          <DashboardSection />
          <AssetInventorySection />
          {isAppDnaSupported && <ApplicationInventorySection />}
          <InvestigateSection />
          <PolicySection />
          <ComplianceSection />
          <AlertsSection />
          {!isChina() && !isGov() && <DataSecurityPostureManagementSection />}
          <DataSecuritySection />
          <SettingsSection />
          <ComputeSection />
          <CodeSecuritySection />
          <AlarmCenterSection />
          {actionPlans && <ActionPlanSection />}
        </Body>
      </div>
    </>
  );
};
