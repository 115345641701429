import {
  type RelativeTimeType,
  type TimeRange,
  type ToNowTime,
} from "requests";

export type TimeRangeComponents = {
  endTime?: number;
  startTime?: number;
  timeAmount?: RelativeTimeType["value"]["amount"];
  timeType?: TimeRange["type"];
  timeUnit?: ToNowTime["value"] | RelativeTimeType["value"]["unit"];
};

/**
 * Build `TimeRange` from `TimeRangeComponents` (that comes from the URL params)
 */
export const buildTimeRange = (
  timeRangeComponents: TimeRangeComponents,
): TimeRange | null => {
  const { endTime, startTime, timeAmount, timeType, timeUnit } =
    timeRangeComponents;

  if (timeType) {
    if (timeType === "relative") {
      const amount = parseInt(timeAmount?.toString() || "");
      if (
        !Number.isNaN(amount) &&
        timeUnit &&
        ["hour", "day", "week", "month", "year"].includes(timeUnit)
      )
        return {
          type: "relative",
          value: {
            amount,
            unit: timeUnit as RelativeTimeType["value"]["unit"],
          },
        };
    } else if (
      timeType === "to_now" &&
      timeUnit &&
      ["year", "epoch", "login"].includes(timeUnit)
    ) {
      return {
        type: "to_now",
        value: timeUnit as ToNowTime["value"],
      };
    } else if (timeType === "absolute" && endTime) {
      return {
        type: "absolute",
        value: {
          endTime,
          startTime,
        },
      };
    }
  }
  return null;
};

/**
 * Build `TimeRangeComponents` (that can be used as URL params) from `TimeRange`.
 * All returned values are string since URL params requires string type.
 */
export const toTimeRangeComponents = (
  timeRange?: TimeRange,
): Record<string, string> => {
  // Allow `undefined` since it happens a lot.
  if (!timeRange) return {};

  const { type, value } = timeRange;

  return type === "absolute"
    ? {
        timeType: type,
        endTime: value.endTime.toString(),
        startTime: (value.startTime || 0).toString(),
      }
    : type === "relative"
      ? {
          timeType: type,
          timeAmount: value.amount.toString(),
          timeUnit: value.unit,
        }
      : {
          timeType: type,
          timeUnit: value,
        };
};
