import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "form";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  cloudAccountsKeys,
  createDataSecurityConfig,
  getDlpStatus,
  saveAwsCloudAccount,
  tenantKeys,
  type AwsRequestPayloadType,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { NextButton, useWizardContext } from "../../../../../components/Wizard";
import {
  ACCOUNT,
  ADD_DETAILS,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  AWS,
  CONFIGURE_DATA_SECURITY,
  CONFIGURE_FW,
  CUSTOM_CONFIGURATION,
  DATA_SECURITY,
  DEFINE_FW,
  DISABLED,
  ENABLED,
  ORGANIZATION,
  REMEDIATION,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
} from "../../../../constants";
import { useGetSupportedFeaturesList } from "../../../../hooks";
import {
  type AwsAddDetailsStepValues,
  type AwsCustomConfigurationStepValues,
} from "../../../../types";
import { parseI18nKeyFromResponse } from "../../../../utils";
import {
  getAccountIdFromRoleArn,
  getWizardStepIndex,
  handleSuccess,
} from "../../../Onboarding/utils";

export default function SaveAndNextButton({
  closeModal,
  disabled,
  validateStep,
}: {
  closeModal?: () => void;
  disabled?: boolean;
  validateStep?: () => boolean;
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();
  const { agentlessApiDiscovery, cloudScanMode, isExpressOnboardingEnabled } =
    useFlags();
  const {
    state: { steps, activeIndex },
  } = useWizardContext();
  const { watch } = useFormContext();

  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);
  const customConfigStepIndex = getStepIndex(CUSTOM_CONFIGURATION);
  const defineFwStepIndex = getStepIndex(DEFINE_FW);
  const configureDataSecurityStepIndex = getStepIndex(CONFIGURE_DATA_SECURITY);
  const configureForwardScanStepIndex = getStepIndex(CONFIGURE_FW);

  const {
    accountType,
    partitionType: awsPartition,
    name,
    groupIds,
  } = (steps[addDetailsStepIndex]?.values ?? {}) as AwsAddDetailsStepValues;
  const isGovcloudSelected = awsPartition?.includes("gov");

  let {
    roleArn = "",
    hierarchySelection,
    externalId,
    securityCapabilities,
    orgSecurityCapabilities,
    mode = "",
    customMemberRoleNameEnabled,
    memberRoleName,
    skipOverrideMemberRoleName,
    unifiedCftDisabled,
    useTenantExternalId,
  } = (steps[customConfigStepIndex]?.values ??
    {}) as AwsCustomConfigurationStepValues;

  if (activeIndex === customConfigStepIndex) {
    [
      roleArn,
      hierarchySelection,
      externalId,
      securityCapabilities,
      orgSecurityCapabilities,
      mode = "",
      customMemberRoleNameEnabled,
      memberRoleName,
      skipOverrideMemberRoleName,
      unifiedCftDisabled,
      useTenantExternalId,
    ] = watch([
      "roleArn",
      "hierarchySelection",
      "externalId",
      "securityCapabilities",
      "orgSecurityCapabilities",
      "mode",
      "customMemberRoleNameEnabled",
      "memberRoleName",
      "skipOverrideMemberRoleName",
      "unifiedCftDisabled",
      "useTenantExternalId",
    ]);
  }

  const accountId = getAccountIdFromRoleArn(roleArn) as string;

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: AWS,
      payload: {
        accountType,
        awsPartition,
      },
    });

  const isAccountType = accountType === ACCOUNT;
  const isOrg = accountType === ORGANIZATION;

  const features = [
    {
      name: AGENTLESS_SCAN,
      state: securityCapabilities[AGENTLESS_SCAN] ? ENABLED : DISABLED,
      ...(isOrg && {
        defaultMemberState:
          securityCapabilities[AGENTLESS_SCAN] &&
          orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
      ...(cloudScanMode &&
        !isGovcloudSelected &&
        securityCapabilities[AGENTLESS_SCAN] && {
          mode,
        }),
    },
    {
      name: AUTO_PROTECT,
      state: securityCapabilities[AUTO_PROTECT] ? ENABLED : DISABLED,
    },
    {
      name: REMEDIATION,
      state: securityCapabilities[REMEDIATION] ? ENABLED : DISABLED,
    },
    {
      name: DATA_SECURITY,
      state: securityCapabilities[DATA_SECURITY] ? ENABLED : DISABLED,
    },
    {
      name: SERVERLESS_SCAN,
      state: securityCapabilities[SERVERLESS_SCAN] ? ENABLED : DISABLED,
      ...(isOrg && {
        defaultMemberState:
          securityCapabilities[SERVERLESS_SCAN] &&
          orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
    agentlessApiDiscovery && {
      name: AGENTLESS_API_DISCOVERY,
      state: securityCapabilities[AGENTLESS_API_DISCOVERY] ? ENABLED : DISABLED,
      ...(isOrg && {
        defaultMemberState:
          securityCapabilities[AGENTLESS_API_DISCOVERY] &&
          orgSecurityCapabilities[AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
  ]
    .filter(Boolean)
    .filter(({ name }) =>
      supportedFeaturesList.includes(name as SecurityCapabilitiesType),
    ) as SecurityCapabilitiesFeatures[];

  let requestData = useMemo(() => {
    return {
      accountId,
      accountType,
      enabled: true,
      features,
      ...(isAccountType
        ? { groupIds }
        : { defaultAccountGroupId: groupIds as unknown as string }),
      ...(!isAccountType && { hierarchySelection }),
      name,
      roleArn,
      ...(!isAccountType && {
        customMemberRoleNameEnabled,
        ...(customMemberRoleNameEnabled && { memberRoleName }),
        skipOverrideMemberRoleName,
        unifiedCftDisabled,
      }),
      useTenantExternalId,
    } as AwsRequestPayloadType;
  }, [
    accountId,
    accountType,
    customMemberRoleNameEnabled,
    features,
    groupIds,
    hierarchySelection,
    isAccountType,
    memberRoleName,
    name,
    roleArn,
    skipOverrideMemberRoleName,
    unifiedCftDisabled,
    useTenantExternalId,
  ]);

  if (securityCapabilities[DATA_SECURITY]) {
    let cloudTrailTopic = steps[defineFwStepIndex]?.values
      ?.cloudTrailTopic as string;
    // in New flow, SNS Topic may be missing prefix and region and account ID
    /* c8 ignore next */
    if (cloudTrailTopic) {
      const prefix = "arn:aws:sns";
      const isMissingPrefix =
        cloudTrailTopic.substring(0, prefix.length) !== prefix;

      // add prefix and region and account ID if missing
      if (isMissingPrefix) {
        const defaultRegion = "us-east-1";

        cloudTrailTopic = `${prefix}:${defaultRegion}:${accountId}:${cloudTrailTopic}`;
      } else {
        cloudTrailTopic;
      }
    }
    const scanOption =
      steps[configureDataSecurityStepIndex]?.values?.scanOption;
    const cloudTrailStorageUUID = steps[defineFwStepIndex]?.values
      ?.storageUUID as string;
    const buckets =
      scanOption === "Custom"
        ? {
            backward: [""],
            forward: [""],
          }
        : {
            backward: ["All"],
            forward: ["All"],
          };

    requestData = {
      ...requestData,
      storageScanConfig: {
        buckets,
        scanOption: scanOption as string,
        snsTopicArn: cloudTrailTopic,
      },
      storageUUID: cloudTrailStorageUUID,
    };
  }

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({
      mutationFn: () => saveAwsCloudAccount({ payload: requestData }),
    });

  const {
    data: provisionData,
    isLoading: dlpApiLoading,
    isFetching,
  } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isAccountType && securityCapabilities[DATA_SECURITY],
  });

  const { mutateAsync: createNewConfig } = useMutation({
    mutationFn: async () => {
      /* c8 ignore next */
      try {
        createDataSecurityConfig({
          payload: {
            storageUUID: requestData?.storageUUID as string,
            accountId,
            cloudType: "aws",
            dssTenantId: provisionData?.dlpTenantId || "",
            accountType: "regular",
            storageScanStatus: "enabled",
            scanOption:
              steps[configureDataSecurityStepIndex]?.values?.scanOption ===
              "Full"
                ? "full"
                : "custom",
            cloudConfig: {
              externalId,
              roleArn,
            },
          },
        });
      } catch {
        closeModal && closeModal();
      }
    },
  });

  const onNextButtonClick = useCallback(async () => {
    try {
      await createConfig();
      /* c8 ignore next */
      if (isAccountType && securityCapabilities[DATA_SECURITY]) {
        await createNewConfig();
      }
      /* c8 ignore next */
      queryClient.invalidateQueries({
        queryKey: cloudAccountsKeys.cloudAccountsTable({
          excludeAccountGroupDetails: "true",
          ...(isExpressOnboardingEnabled && { includePendingAccounts: "true" }),
        }),
      });
      toast(handleSuccess(false, intl), { appearance: "success" });
    } catch (error) {
      toast(parseI18nKeyFromResponse(intl, error), {
        appearance: "error",
      });
    }
  }, [
    createConfig,
    isAccountType,
    securityCapabilities,
    queryClient,
    isExpressOnboardingEnabled,
    toast,
    intl,
    createNewConfig,
  ]);

  const nextBtndisabled =
    isFeaturesLoading ||
    dlpApiLoading ||
    isAccountCreating ||
    disabled ||
    isFetching;

  return (
    <NextButton
      disabled={nextBtndisabled}
      validateStep={validateStep}
      onClick={() => {
        /** In custom Config page, if data security is not enabled then only call save API.
         *  If enabled, then call SAVE api on click of next button from Configure forward scan step of data security */
        (activeIndex === customConfigStepIndex &&
          !securityCapabilities[DATA_SECURITY]) ||
        activeIndex === configureForwardScanStepIndex
          ? onNextButtonClick()
          : undefined;
      }}
    />
  );
}
