import { z } from "zod";
import {
  AlertStatusEnumSchema,
  GitProvidersEnumSchema,
  IACFrameworksEnumSchema,
  ObjectExposureEnumSchema,
  TimeRangeTypeEnumSchema,
} from "../../alerts";
import {
  PolicyCategoryEnumSchema,
  PolicyClassEnumSchema,
  PolicyModeEnumSchema,
  PolicySubTypesEnumSchema,
  PolicyTypesEnumSchema,
  SeverityEnumSchema,
} from "../../policies";

const SuggestionOptions = z.object({
  options: z.array(z.string()),
});
const SuggestionBooleanOptions = z.object({
  options: z.array(z.coerce.boolean()),
});

export const PostSuggestionsResponseSchema = z.object({
  suggestions: z.array(z.string()),
});

//********
//alerts//
//********

export const GetAlertSuggestionsResponseSchema = z.object({
  "account.group": SuggestionOptions,
  "alert.id": SuggestionOptions,
  "alert.status": z.object({
    options: z.array(AlertStatusEnumSchema),
  }),
  "alertRule.name": SuggestionOptions,
  "asset.class": SuggestionOptions,
  "buildtime.resourceName": SuggestionOptions,
  "cloud.account": SuggestionOptions,
  "cloud.accountId": SuggestionOptions,
  "cloud.region": SuggestionOptions,
  "cloud.service": SuggestionOptions,
  "git.filename": SuggestionOptions,
  "git.provider": z.object({
    options: z.array(GitProvidersEnumSchema),
  }),
  "git.repository": SuggestionOptions,
  "iac.framework": z.object({
    options: z.array(IACFrameworksEnumSchema),
  }),
  malware: SuggestionOptions,
  "object.classification": SuggestionOptions,
  "object.exposure": z.object({
    options: z.array(ObjectExposureEnumSchema),
  }),
  "object.identifier": SuggestionOptions,
  "policy.complianceRequirement": SuggestionOptions,
  "policy.complianceSection": SuggestionOptions,
  "policy.complianceStandard": SuggestionOptions,
  "policy.label": SuggestionOptions,
  "policy.name": SuggestionOptions,
  "policy.remediable": SuggestionOptions,
  "policy.severity": z.object({
    options: z.array(SeverityEnumSchema),
  }),
  "policy.subtype": z.object({
    options: z.array(PolicySubTypesEnumSchema),
  }),
  "policy.type": z.object({
    options: z.array(PolicyTypesEnumSchema),
  }),
  "resource.group": SuggestionOptions,
  "resource.id": SuggestionOptions,
  "resource.name": SuggestionOptions,
  "resource.type": SuggestionOptions,
  "timeRange.type": z.object({
    options: z.array(TimeRangeTypeEnumSchema),
  }),
});

export const AlertSuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetAlertSuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetAlertSuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//************
//compliance//
//************

export const GetComplianceStandardSuggestionsResponseSchema = z.object({
  "account.group": SuggestionOptions,
  "cloud.account": SuggestionOptions,
  "cloud.region": SuggestionOptions,
  "resource.group": SuggestionOptions,
  "policy.complianceStandard": SuggestionOptions,
  "policy.complianceRequirement": SuggestionOptions,
  "policy.complianceSection": SuggestionOptions,
  "resourceList.tag": SuggestionOptions.optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
});

export const ComplianceStandardSuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetComplianceStandardSuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetComplianceStandardSuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//*******************
//compliance report//
//*******************

export const GetComplianceReportSuggestionsResponseSchema = z.object({
  "account.group": SuggestionOptions,
  "cloud.account": SuggestionOptions,
  "cloud.region": SuggestionOptions,
  "policy.complianceStandard": SuggestionOptions,
  "report.email.recipients": SuggestionOptions,
  "report.frequency": SuggestionOptions,
  "report.schedule": SuggestionOptions,
  "resource.group": SuggestionOptions,
  "schedule.status": SuggestionOptions,
  "resourceList.tag": SuggestionOptions.optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
});

export const ComplianceReportSuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetComplianceReportSuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetComplianceReportSuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//*******************
//asset explorer//
//*******************

export const GetAssetExplorerSuggestionsResponseSchema = z.object({
  "account.group": SuggestionOptions,
  "cloud.account": SuggestionOptions,
  "cloud.region": SuggestionOptions,
  "policy.complianceStandard": SuggestionOptions,
  "policy.complianceRequirement": SuggestionOptions,
  "policy.complianceSection": SuggestionOptions,
  "report.email.recipients": SuggestionOptions,
  "report.frequency": SuggestionOptions,
  "report.schedule": SuggestionOptions,
  "resource.group": SuggestionOptions,
  "schedule.status": SuggestionOptions,
  "cloud.service": SuggestionOptions,
  "resource.id": SuggestionOptions,
  "resource.name": SuggestionOptions,
  "resource.type": SuggestionOptions,
  "resourceList.tag": SuggestionOptions.optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
});

export const AssetExplorerSuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetAssetExplorerSuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetAssetExplorerSuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//**********
//inventory//
//**********
export const GetInventorySuggestionsResponseSchema = z.object({
  "asset.class": SuggestionOptions,
  "cloud.service": SuggestionOptions,
  "resource.group": SuggestionOptions,
  "policy.complianceRequirement": SuggestionOptions,
  "policy.complianceStandard": SuggestionOptions,
  "resource.type": SuggestionOptions,
  "policy.complianceSection": SuggestionOptions,
  "account.group": SuggestionOptions,
  "cloud.region": SuggestionOptions,
  "cloud.accountId": SuggestionOptions,
  "resourceList.tag": SuggestionOptions.optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
});

export const InventorySuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetInventorySuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetInventorySuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//**********
//policies//
//**********

export const GetPolicySuggestionsResponseSchema = z.object({
  "policy.name": SuggestionOptions,
  "policy.type": z.optional(
    z.object({
      options: z.array(PolicyTypesEnumSchema.or(z.string())).catch([]),
    }),
  ),
  "policy.label": SuggestionOptions,
  "policy.complianceStandard": SuggestionOptions,
  "policy.complianceRequirement": SuggestionOptions,
  "policy.complianceSection": SuggestionOptions,
  "policy.category": z.object({
    options: z.array(PolicyCategoryEnumSchema.or(z.string())).catch([]),
  }),
  "policy.class": z.object({
    options: z.array(PolicyClassEnumSchema.or(z.string())).catch([]),
  }),
  "policy.remediable": SuggestionBooleanOptions,
  "policy.severity": z.object({
    options: z.array(SeverityEnumSchema).catch([]),
  }),
  "policy.subtype": z.object({
    options: z.array(PolicySubTypesEnumSchema.or(z.string())).catch([]),
  }),
  "policy.enabled": SuggestionBooleanOptions,
  "policy.policyMode": z.object({
    options: z.array(PolicyModeEnumSchema).catch([]),
  }),
  "policy.rule.type": z
    .object({
      options: z.array(PolicyTypesEnumSchema).catch([]),
    })
    .nullish(),
});

export const PolicySuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetPolicySuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetPolicySuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//**********
//data inventory//
//**********
export const GetDataInventorySuggestionsResponseSchema = z.object({
  "cloud.service": SuggestionOptions,
  "account.group": SuggestionOptions,
  "cloud.region": SuggestionOptions,
  "resource.exposure": SuggestionOptions,
  "resource.name": SuggestionOptions,
  "container.name": SuggestionOptions,
  "object.tag": SuggestionOptions,
  "object.owner": SuggestionOptions,
  "object.classification": SuggestionOptions,
});

export const DataInventorySuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetDataInventorySuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetDataInventorySuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//**********
//action plans//
//**********

export const GetActionPlanSuggestionsResponseSchema = z.object({
  status: SuggestionOptions,
  "finding.type": SuggestionOptions,
});

export const ActionPlanSuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetActionPlanSuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetActionPlanSuggestionsResponseSchema.keyof(),
  query: z.string(),
});

//**********
//audit logs//
//**********

export const GetAuditLogSuggestionsResponseSchema = z.object({
  resourceType: SuggestionOptions,
  user: SuggestionOptions,
});

export const AuditLogSuggestionRequestSchema = z.object({
  appliedFilters: z.array(
    z.object({
      filterName: GetAuditLogSuggestionsResponseSchema.keyof(),
      query: z.string(),
    }),
  ),
  filterName: GetAuditLogSuggestionsResponseSchema.keyof(),
  query: z.string(),
});
