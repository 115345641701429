import { lazy } from "react";
import { createRoute, privateRootRoute } from "router";

const CommandCenter = lazy(() =>
  import("./CommandCenter").then((res) => ({
    default: res.CommandCenter,
  })),
);

export const commandCenterRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "new-command-center",
  component: CommandCenter,
});
